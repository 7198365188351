window._ = require('lodash');
// import _ from 'lodash';
// window._ = _;

// import $ from 'jquery';
// window.$ = $;

// Importar select2
// import select2 from 'select2';
// select2();

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('popper.js').default;
    require('select2');
    window.echarts = require('echarts');
    require('jquery-mask-plugin');
    require('jquery-confirm');
    require('jquery-ui');
    require('iframe-resizer/js/iframeResizer.min.js');
    require('overlayscrollbars/js/jquery.overlayScrollbars.min')
    require('bootstrap');
    require('bootstrap-slider');
    require('admin-lte');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');
// import axios from 'axios';
// window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// // window.Pusher = require('pusher-js');
// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });